.list-group-div{
  border-radius: 10px;
  box-shadow: 0px 0px 3px 3px #e9e1e1;
}

.inputLocation{
  border-radius: 5px;
  padding: 5px;
}
.inputDuration{
  border-radius: 5px;
  box-shadow: 0px 0px 4px 2px #e5dcdc;
  border: none;
  width: 60px;
  margin-left: 5px;
}
.butonat{
  display: flex;
  justify-content: end;
  gap: 10px;
  .deleteButton{
      border: none;
      padding: 5px 10px;
      border-radius: 6px;
      background: #e8040c;
      color: white;
  }
  .editButton{
      border: none;
      padding: 5px 10px;
      border-radius: 6px;
      background: #8fb399;
      color: white;
  }
}
.addLocation{
  border: none;
  background-color:#677a6d ;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
}
.aboutSalonNameEdit{
  font-weight: 600; 
}
.iconLocation{
  width: 22px;
  margin-top: 4px;
}
.salonImages{
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
  border: dashed red;
  transition: all 0.2s ease-out;
}
.actives{
  border: 2px solid black;
}

@media only screen and (max-width: 800px) {
.salonImages{
      width: 267px;
      height: 200px;
      margin-bottom: 20px;
      border: dashed red;
      transition: all 0.2s ease-out;
  }
  .bgSalon{
      width: 81%;
      margin-left: 20%;
  }
  .butonat{
      display: flex;
      justify-content: center;
      gap: 10px
  }
}
.actives{
  border: 2px solid black;
}
.servicediv{
  display: flex;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  height: auto;
}
.editButtonSalon{
  background: none;
  color: grey;
  padding: 2px 18px;
  border: 2px solid green;
  width: 60px;

}
.staffButtonSalon{
  background: none;
  color: grey;
  padding: 2px 18px;
  border: 2px solid gray;
}
.addButtonSalon{
  background: none;
  color: #7474d9;
  padding: 2px 10px;
  border: 2px solid #7474d9;
  width: 60px;
}
// input[type=checkbox] {
//     position: relative;
//       cursor: pointer;
// }
// input[type=checkbox]:before {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     top: 0;
//     left: 0;
//     border: 2px solid #9c9898;
//     border-radius: 3px;
//     background-color: white;
// }
// input[type=checkbox]:checked:after {
//     content: "";
//     display: block;
//     width: 5px;
//     height: 10px;
//     border: solid black;
//     border-width: 0 2px 2px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//     position: absolute;
//     top: 2px;
//     left: 6px;
// }

.groupList{

  @media only screen and (max-width: 800px) {
    width: 104%;
  }

}

.listText{
  text-align: center;
}
.listGroup{
  width: 99%;
    margin-left: 0%;
}
.timeListGroup{
  display: flex;
  margin-left: -4.2%;
  margin-bottom: 7px;
  gap: 14px;

  @media only screen and (max-width: 800px) {
   
    display: flex;
    gap: 17px;
    margin-left: -10%;
    margin-bottom: 7px;
  }
}  
.inputWorkingHours{
  border: none;
    padding: 2px;
    border-radius: 4px;
    /* border: 1px solid #e54f4f; */
    box-shadow: 0px 0px 5px 1px #e1d9d9;
}
.workingHours{
  display: flex;
  justify-content: end;
  gap: 7px;
  margin-left: 21px;
}
.addButtonSalon{
  background: none;
  color: #7474d9;
  padding: 2px 10px;
  border: 2px solid #7474d9;
  width: 60px;
}

.editButtonSalon{
  background: none;
  color: grey;
  padding: 2px 18px;
  border: 2px solid green;
  width: 60px;

}

.mapEditSalon{
  width:'500px';
  @media only screen and (max-width: 800px) {
      width:'200px'
  }
}
.aboutLocationEdit{
  margin-top: 0.5%;
}

.listatBreak{
  @media only screen and (max-width: 800px) {
  
    display: flex;
    flex-direction: column;
    width: 105%;
    /* margin-right: -34%; */
    margin-left: -4%;
    font-size: 10px;
    }
}
.shaban{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .butonatEditDelete{
    // margin-top: 58px;
    .forButtons{
      justify-content: end;
    }
  }
    .imageEditSalon{
      width: 63%;
      max-height: 108px;
      border-radius: 15px 2px;
      box-shadow: 0px 0px 5px 3px #eff2f5

    }
    
padding: 15px 5px;
border: 1px solid rgb(235, 232, 232);
border-radius: 15px 2px;
margin-bottom: 25px;
.divImageContainer{
  height: 15vh;
}


.forDisable[disabled]{
background-color:rgb(245, 245, 245);
}
input:disabled{
background-color:rgb(245, 245, 245);
} 
label{
font-size: 12px;
color: gray;
}
}

@media only screen and (max-width: 800px) {
.shaban{
.divImageContainer{
  height: 6vh;
}
}

}
@media only screen and (max-width: 800px) {
.shaban{
flex-wrap: wrap;
.imageEditSalon{
  width: 170%;
  border-radius: 15px 2px;
  box-shadow: 0px 0px 5px 3px #eff2f5;
  height: 216%;
}

label{
  font-size: 10px;
  color: gray;
}
input{
  font-size: 13px;


}
}
}
@media only screen and (max-width: 800px) {
.shaban{
.forDisable{
  margin-left: 60%;
}


.butonatEditDelete{
  margin-top: 58px;
  .forButtons{
    justify-content: end;
  }
}
}
}

.mapEditSalon{
width:'500px';
@media only screen and (max-width: 800px) {
    width:'200px'
}
}
.aboutLocationEdit{
margin-top: 0.5%;
}