.noSalon{
    display: flex;
    flex-direction: column;
    transform: translate(0, 350%);
    justify-content: center;
    align-items: center;
  hr{
    margin-left: 2%;
  }
}

.noSalonResponsive{
  display: flex;
  flex-direction: column;
  transform: translate(0, 450%);
  justify-content: center;
  align-items: center;
  margin-left: 19%;

hr{
  margin-left: 2%;
}
}