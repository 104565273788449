.iconKey{
    width: 20px;
}
.iconKeyModal{
    width: 20px;

}
.addButtonModalLanguage{

    color: #FF5758;
}
.addButtonLanguageMobile{
    color: #FF5758;

}