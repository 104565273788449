body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* @font-face {
  font-family: myFirstFont;
  src:url(../public/assets/font/Quicksand-SemiBold/Quicksand-SemiBold.ttf);

}

* {
  font-family: myFirstFont;
  color: #424549;
} */