.service_layout {
    // position:fixed;
    // opacity: "0.1";
    // background: white;
    .service_image {
        max-height: 50px;
    }
}
.edit_service_modal_layout {
    .service_image {
        max-height: 50px;
    }
}
.eyeStyle{
    text-decoration: none;
    color: #393434;
}
.eyeStyle:hover{
    color: #c54141;
}
.service_imageRes{
    max-width: 20%;
}